
// Libraries
import * as React from 'react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import DuoSection from '../components/duoSection'
import Button from '../components/button'

class ForgottenPasswordSuccessPage extends React.Component {
	render() {
		return <Layout className="page--success nav-blue-half no-footer">
			<Seo title="Forgotten Password Success" />
			<DuoSection success>
				<div>
					<div className="content-wrap">
						<h1>Success!</h1>
						<p>Please check your email to find your password reset link.</p>
						<Button to="/login" hollow colorEndeavour>Login</Button>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}
}

export default ForgottenPasswordSuccessPage
